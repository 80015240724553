import { tween } from 'shifty/dist/shifty.core';

(function () {
   // Repairability Score
   let theScore = App.repairabilityScore;
   let teardownConclusion;

   // Color variables
   let colors = [
      '#E01410',
      '#EB2C0A',
      '#EF5803',
      '#F38800',
      '#F4B700',
      '#F0DC02',
      '#DAE60D',
      '#9ADA0D',
      '#5DD113',
      '#2DCF1C',
      '#0BCD20',
   ];

   // Angle for meter hand
   let rotationStart = 135;
   let rotationEnd = 405;
   let rotationTargetPosition;

   function setMeterPosition(repairabilityScore) {
      let difference = (rotationEnd - rotationStart) / 10;
      rotationTargetPosition = difference * repairabilityScore + rotationStart;
   }

   // Angle for color bar
   let colorBarStart = 227;
   let colorBarEnd = 454;
   let colorBarTargetPosition;

   function setColorBarPosition(repairabilityScore) {
      let difference = (colorBarEnd - colorBarStart) / 10;
      colorBarTargetPosition = difference * repairabilityScore + colorBarStart;
   }

   function showScoreNumber() {
      const meterCounter = document.querySelector('#meter-counter');
      const meterProgress = document.querySelector('#meterProgress');
      const meterStroke = document.querySelector('#meterStroke');
      tween({
         from: { scaleY: 0 },
         to: { scaleY: 1 },
         easing: 'bouncePast',
         start: () => {
            meterCounter.style.transform = 'scale(0)';
            meterCounter.style.opacity = 1;
            meterCounter.style.textShadow = '0 0 30px ' + colors[theScore];
         },
         duration: 250,
         render: ({ scaleY }) => {
            meterCounter.style.transform = `scale(${scaleY})`;
         },
      }).then(() => {
         meterProgress.style.strokeDashoffset = colorBarTargetPosition;
         meterStroke.style.strokeWidth = 18;
      });
   }

   function fillTheColorBar() {
      setColorBarPosition(theScore);
      const meterProgress = document.querySelector('#meterProgress');
      tween({
         from: { colorBarPosition: colorBarStart },
         to: { colorBarPosition: colorBarTargetPosition },
         easing: 'easeInExpo',
         delay: 1385,
         duration: 2000,
         render: ({ colorBarPosition }) => {
            meterProgress.style.strokeDashoffset = colorBarPosition;
         },
      }).then(showScoreNumber);
   }

   function rotateMeterHand() {
      setMeterPosition(theScore);
      const meterBar = document.querySelector('#meterBar');
      tween({
         from: { rotation: rotationStart },
         to: { rotation: rotationTargetPosition },
         easing: 'easeInExpo',
         delay: 1385,
         duration: 2000,
         render: ({ rotation }) => {
            meterBar.style.transform = `rotate(${rotation}deg)`;
         },
      }).then(vibrateMeterHand);
   }

   function vibrateMeterHand() {
      if (Browser.name === 'ie') {
         return;
      }

      const forwardRotation = rotationTargetPosition - theScore / 3;
      const reverseRotation = rotationTargetPosition + theScore / 6;
      const seconds = 1 / (theScore + 5);
      const tweenConfig = {
         easing: 'swingFromTo',
         duration: seconds * 1000,
         render: ({ rotation }) => {
            meterBar.style.transform = `rotate(${rotation}deg)`;
         },
      };

      const forwardConfig = {
         from: { rotation: forwardRotation },
         to: { rotation: reverseRotation },
         ...tweenConfig,
         finish: () => tween(reverseConfig),
      };

      const reverseConfig = {
         to: { rotation: forwardRotation },
         from: { rotation: reverseRotation },
         ...tweenConfig,
         finish: () => tween(forwardConfig),
      };

      tween(forwardConfig);
   }

   let meterHandAnimated = false;
   function animateRepairMeter() {
      rotateMeterHand();
      fillTheColorBar();

      const colorBar = document.querySelector('#meterStroke');
      const meterCounter = document.querySelector('#meter-counter');
      if (Browser.name !== 'ie') {
         tween({
            from: { color: colors[0] },
            to: { color: colors[theScore] },
            easing: 'easeFromTo',
            delay: 1385,
            duration: 2000,
            render: ({ color }) => {
               colorBar.style.stroke = color;
            },
         });
      } else {
         colorBar.style.stroke = colors[theScore];
      }
      Object.assign(meterCounter.style, { opacity: 0, scaleY: 0 });
      meterHandAnimated = true;
   }

   function checkRepairMeterFocus() {
      // Make sure we only animate it once.
      if (!meterHandAnimated) {
         let viewportHeight = window.getSize().y;
         let conclusionLocation = teardownConclusion.getPosition().y;
         let conclusionHeight = 250; // Approximate height of the gauge.
         if (getScroll().y > conclusionLocation - (viewportHeight - conclusionHeight)) {
            animateRepairMeter();
         }
      }
   }

   onDomReady(function () {
      if ((teardownConclusion = $('teardownConclusion'))) {
         checkRepairMeterFocus();
         window.addEvent('scroll:throttle(100):pause(100)', checkRepairMeterFocus);
      }
   });
})();
