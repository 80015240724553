/**
 * Class KeyboardNavigation:
 *
 * Simplifies the process of attaching actions to keyboard shortcuts. Allows
 * you to attach a function call to a simplified "key combination shorthand".
 * You can specify an "event filter" function which will be passed the key
 * event and should return false if the event is to be ignored. The default
 * event filter ignores key events orginating from inputs and text areas.
 *
 * An example invocation:
 *
 * var keynav = new KeyboardNavigation({
 *    'keys': {
 *       'k': handlePrev,
 *       'j': handleNext,
 *       'S-k': handleTop,
 *       'S-j': handleBottom
 *    }
 * });
 *
 * "S-k" indicates that the "shift" modifier should be true and that the key
 * should be "k". There are similar shorthands for "meta", "alt", and
 * "control" ("M", "A", and "C" respectively). You can attach multiple
 * modifiers to one key, and the order in which the modifiers are specified
 * doesn't matter.
 *
 * TODO: It would be easy to add support for submapping by adding spaces to the
 * shorthand (e.g. "g t" to indicate that "g" should be hit and then "t"). It
 * would also be relatively easy to add alternation, so that you could specify
 * that either "alt" or "control" should work as the modifier.
 */
// eslint-disable-next-line no-var
export var KeyboardNavigation = new Class({
   Implements: [Options, Events],

   options: {
      keys: {},
      eventSource: document,
      eventFilter: null,
   },

   keys: {},

   initialize: function (options) {
      this.setOptions(options);
      this.addKeys(this.options.keys);

      if (!this.options.eventFilter) {
         this.options.eventFilter = this.filterInputs;
      }

      this.options.eventSource.addEvent('keydown', this.handleKeyup.bind(this));
   },

   handleKeyup: function (ev) {
      if (this.options.eventFilter(ev)) {
         let key = this.getKeyFromEvent(ev);
         this.checkAndDispatch(key, ev);
      }
   },

   addKeys: function (keys) {
      Hash.each(keys, this.addKey, this);
   },

   addKey: function (action, key) {
      let pieces = key.split('-');
      let actualKey = pieces.pop();
      let modifiers = pieces.sort().join('').toUpperCase();
      let coded = modifiers + actualKey;
      this.keys[coded] = action;
   },

   getKeyFromEvent: function (ev) {
      let modifiers = ['alt', 'control', 'meta', 'shift'];
      let key = [];
      modifiers.each(function (m) {
         if (ev[m]) {
            key.push(m.charAt(0).toUpperCase());
         }
      });
      return key.join('') + ev.key;
   },

   checkAndDispatch: function (key, ev) {
      if (key in this.keys) {
         this.keys[key](ev);
         ev.stop();
      }
   },

   filterInputs: function (ev) {
      // Ignore inputs, textareas, and ProseMirror
      return (
         !['INPUT', 'TEXTAREA'].contains(ev.target.tagName) &&
         !ev.target.classList.contains('ProseMirror')
      );
   },
});

/**
 * Class UpDownNavigation:
 *
 * Wraps an instance of KeyboardNavigation, and supplies key shortcuts for
 * moving up and down a vertical sequence of DOM elements. The DOM elements
 * must be laid out from top to bottom, as the positioning logic assumes that
 * elements that come earlier in the list of items are at the top. The class
 * takes care of figuring out which item is currently focused based on a simple
 * heuristic and updates the currently focused element when the page is
 * scrolled.
 *
 * The current item is the first one in the list whose top is beneath the
 * current scroll offset, which is usually the first element whose top is
 * visible on the page. If the first item is beneath the bottom of the
 * viewport, however, it will still be the currently-focused item.
 * Consequently, it's a good idea to have as the first element in your list
 * something that will always show up at the top of the screen.
 *
 * Example invocation:
 *
 * var upDownNav = new UpDownNavigation({
 *    'items': $$('#intro').concat($$('.step')),
 *    'scrollContext': 8
 * });
 *
 * Note the use of $$ and concat to put the intro (when it's present) at the
 * front of the list of items.
 */
// eslint-disable-next-line no-var
export var UpDownNavigation = new Class({
   Implements: [Events, Options],

   options: {
      prevKey: 'k',
      topKey: 'S-k',
      nextKey: 'j',
      bottomKey: 'S-j',
      eventSource: document,
      scrollContainer: window,
      eventFilter: null,
      items: [],
      scrollContext: 8,
      scrollDuration: 0.3,
      scrollEase: 300,
   },

   items: [],

   currentPosition: 0,

   initialize: function (options) {
      this.setOptions(options);

      let keys = {};
      keys[this.options.prevKey] = this.handlePrev.bind(this);
      keys[this.options.topKey] = this.handleTop.bind(this);
      keys[this.options.nextKey] = this.handleNext.bind(this);
      keys[this.options.bottomKey] = this.handleBottom.bind(this);

      this.keymapper = new KeyboardNavigation({
         keys: keys,
         eventSource: this.options.eventSource,
         eventFilter: this.options.eventFilter,
      });

      this.handleScroll = this.handleScroll.bind(this);
      this.setUpdateOnScroll(true);
      this.addItems(this.options.items);
      this.currentPosition = this.calculatePosition();
   },

   handlePrev: function () {
      let newPos = Math.max(0, this.currentPosition - 1);
      if (newPos != this.currentPosition) {
         this.currentPosition = newPos;
         this.jumpToPosition(newPos);
      }
   },

   handleTop: function () {
      if (this.currentPosition != 0) {
         this.currentPosition = 0;
         this.jumpToPosition(0);
      }
   },

   handleNext: function () {
      let newPos = Math.min(this.items.length - 1, this.currentPosition + 1);
      if (newPos != this.currentPosition) {
         this.currentPosition = newPos;
         this.jumpToPosition(newPos);
      }
   },

   handleBottom: function () {
      let bottom = this.items.length - 1;
      if (bottom != this.currentPosition) {
         this.currentPosition = bottom;
         this.jumpToPosition(bottom);
      }
   },

   handleScroll: function () {
      this.setUpdateOnScroll(false);
      let lastOffset = this.options.scrollContainer.getScroll().y;

      // eslint-disable-next-line no-var
      var checkScrollStop = function () {
         let newOffset = this.options.scrollContainer.getScroll().y;
         let newPos;
         if (newOffset == lastOffset) {
            this.setUpdateOnScroll(true);
            newPos = this.calculatePosition();
            if (newPos != this.currentPosition) {
               this.currentPosition = newPos;
               this.fireEvent('setposition', [
                  this.items[this.currentPosition].el,
                  this.currentPosition,
               ]);
            }
         } else {
            lastOffset = newOffset;
            window.setTimeout(checkScrollStop, this.options.scrollEase);
         }
      }.bind(this);

      window.setTimeout(checkScrollStop, this.options.scrollEase);
   },

   jumpToPosition: function (pos) {
      let el = this.items[pos].el;
      this.setUpdateOnScroll(false);
      this.fireEvent('beforejump', el);
      el.jumpTo({
         offset: this.options.scrollContext,
         duration: this.options.scrollDuration,
         onComplete: function () {
            this.setUpdateOnScroll(this.items.length > 0);
         }.bind(this),
      });
   },

   addItems: function (items) {
      this.items = this.items.concat(
         items.map(function (item) {
            let relativeTo =
               this.options.scrollContainer == window ? undefined : this.options.scrollContainer;

            return {
               el: item,
               top: item.getCoordinates(relativeTo).top,
            };
         }, this)
      );
   },

   calculatePosition: function () {
      let scroll = this.options.scrollContainer.getScroll().y;
      let i, item;
      for (i = 0; i < this.items.length; i++) {
         item = this.items[i];
         if (item.top > scroll) {
            return i;
         }
      }
      return i - 1;
   },

   setUpdateOnScroll: function (yes) {
      let action = yes ? 'addEvent' : 'removeEvent';
      this.options.scrollContainer[action]('scroll', this.handleScroll);
   },
});
