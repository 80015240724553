import { Tooltip } from 'Shared/tooltips';

// eslint-disable-next-line no-var
export var ItemHover = (window.ItemHover = new Class({
   initialize: function (li) {
      this.li = li;
      this.featured = li.hasClass('featured');
      this.name = li.getElement('.itemName').get('text');
      this.guideinfo = li.getProperty('data-guideinfo');
      this.imageURL = li.getProperty('data-image');
      this.purchaseURL = li.getProperty('data-purchaseUrl');
      this.wikiURL = li.getProperty('data-wikiUrl');
      this.editURL = li.getProperty('data-editUrl');
      this.text = li.getProperty('data-text');
      this.tooltipClass = li.getProperty('data-tooltip-class') || 'itemHover';
      this.infoButtonClass = li.getProperty('data-info-button-class') || '';
      this.buyButtonClass = li.getProperty('data-buy-button-class') || '';
      this.tip = this.buildTooltip();

      li.addEvents({
         mouseenter: li.addClass.pass('active', li),
         mouseleave: li.removeClass.pass('active', li),
      });
   },

   buildTooltip: function () {
      let content = new Element('div.' + this.tooltipClass);
      let link;

      link = this.wikiURL;

      if (this.imageURL) {
         content.addClass('hasImage');
         content.adopt(
            new Element('div.thumbnail').adopt(
               new Element('a', {
                  href: link,
                  styles: {
                     backgroundImage: 'url(' + this.imageURL + ')',
                  },
               })
            )
         );
      }

      content.adopt(
         new Element('h3.title').adopt(
            new Element('a', {
               href: link,
               text: this.name,
            })
         )
      );

      content.adopt(
         new Element('p.guideinfo', {
            text: this.guideinfo,
         })
      );

      let text = _js(
         "This {item} doesn't have a description yet, but you can help! {a1}Improve the {name} wiki{a2} with a quick explanation."
      );
      content.adopt(
         new Element('p.description', {
            html:
               htmlEncode(this.text) ||
               text.substitute({
                  item: this.li.getParent('ul').getProperty('data-itemType'),
                  name: htmlEncode(this.li.getElement('.itemName').get('text')),
                  a1: '<a target="_blank" rel="noreferrer" href="' + this.editURL + '">',
                  a2: '</a>',
               }),
         })
      );

      content.adopt(new Element('div.clearer'));

      if (this.purchaseURL) {
         content.adopt(
            new Element('p.purchaseLink').adopt(
               new Element('a', {
                  text: _js('View Item'),
                  href: this.purchaseURL,
                  class: this.buyButtonClass,
               })
            )
         );
      }

      if (this.wikiURL && this.text) {
         content.adopt(
            new Element('p.wikiLink').adopt(
               new Element('a', {
                  text: _js('Info'),
                  href: this.wikiURL,
                  class: this.infoButtonClass,
               })
            )
         );
      }

      content.adopt(new Element('div.clearer'));

      let nudge, offset, arrowInset;
      if (this.featured) {
         nudge = 13;
         offset = -10;
         arrowInset = 16;
      } else {
         nudge = 24;
         offset = -7;
         arrowInset = 32;
      }

      let tip = new Tooltip(this.li, {
         autoshow: true,
         showDelay: 400,
         side: 'top',
         arrowAlign: 'left',
         offset: offset,
         nudge: nudge,
         arrowInset: arrowInset,
         text: content,
         maxWidth: 300,
      });

      return tip;
   },
}));
