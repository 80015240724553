function setBannerData(bannerHtml) {
   let bannerContainer = $E('.js-guide-promo-banner-container');
   if (bannerContainer) {
      bannerContainer.set('html', bannerHtml);
   }
}

onDomReady(function () {
   if (window.App.siteName != 'ifixit') {
      return;
   }

   let requestUri = 'internal/guide_banner/' + App.guideid;

   new Request.API_2_0(requestUri, {
      onSuccess: setBannerData,
   }).send();
});
