import { Auth } from 'Shared/auth';
import { Modal } from 'Shared/modal';
import { SettingsCookie } from 'Shared/utils';
import { StatusNotice } from 'Shared/status_notice';
import { ItemHover } from 'Guide/item_hover';
import { ImageMenu } from 'Shared/FrameModules/Image/image_menu';
import { UpDownNavigation } from 'Shared/keyboard_navigation';

onDomReady(async function () {
   const { CommentManager, scrollToEditor } = await import('../FrameModules/comments');

   let embedAdded = false;

   function getEmbedHtml(width, type) {
      let height = Math.floor(width * window.shared_constants.Global('EMBED_ASPECT_RATIO'));
      let textarea = $$('.js-embed-' + type + '.js-embed-text')[0];
      let embedHtml = textarea.get('data-embed-template').replace('{width}', width);

      if (type == 'single') {
         embedHtml = embedHtml.replace('{height}', height);
      }

      return embedHtml;
   }

   let swapImage = function (thumbDiv, mediaDiv) {
      thumbDiv
         .getParent('.js-step')
         .getElements('.js-step-thumbnail-container')
         .removeClass('active');

      thumbDiv.addClass('active');

      let newLargeImage = $(thumbDiv.getAttribute('data-fullimg'));
      let oldLargeImage = mediaDiv.getElement('img.visible');
      // If newLargeImage isn't an <img> or oldLargeImage doesn't exist
      // we shouldn't do swapping as lazy-loading hasn't happened
      if (!newLargeImage.src || !oldLargeImage) {
         return;
      }

      oldLargeImage.removeClass('visible');
      newLargeImage.addClass('visible');

      // Disable the old menu and enable the new one. Note that on page load,
      // only the first image gets a menu installed, so for the other images we
      // need to create a new menu on the first view.
      let menu = ImageMenu.getMenu(oldLargeImage);
      if (menu) {
         menu.disable();
         menu = ImageMenu.getMenu(newLargeImage);
         if (!menu) {
            menu = ImageMenu.installMenu(newLargeImage);
            newLargeImage.addClass('hasMenu');
         }
         menu.enable();
      }
   };

   function toggleStepComments(stepContainer, duration, show, el) {
      duration = duration !== undefined ? duration : 0.5;

      let expandingContainer = stepContainer.getElement('.comments-container');
      const manager = getCommentManager(stepContainer);

      // If the caller didn't specify whether to show (show === true) or hide
      // (show === false) then toggle the comment from its current state.
      if (show === undefined) {
         show = expandingContainer.hasClass('closed');
      }

      if (expandingContainer) {
         if (!show) {
            TweenMax.to(expandingContainer, duration, {
               height: 0,
               opacity: 0,
               ease: Expo.easeOut,
               onComplete: function () {
                  expandingContainer.addClass('closed');
               },
            });
         } else {
            manager.loadComments().then(function () {
               TweenMax.set(expandingContainer, { height: 'auto', opacity: 1 });
               TweenMax.from(expandingContainer, duration, {
                  height: 0,
                  opacity: 0,
                  ease: Expo.easeOut,
                  onComplete: function () {
                     expandingContainer.removeClass('closed');
                     if (el && el.hasClass('js-also-scroll-to-editor')) {
                        scrollToEditor(el);
                     }
                  },
               });
            });
         }
      }
   }

   function getCommentManager(stepContainer) {
      const commentContainer = stepContainer.getElement('.js-comment-container');
      let manager = commentContainer.retrieve('commentManager');
      if (!manager) {
         manager = new CommentManager(commentContainer);
         commentContainer.store('commentManager', manager);
      }
      return manager;
   }

   function updateEmbedHtml(ev, el) {
      let checkClearClass,
         $this = $(this);

      if ($this.hasClass('js-embed-width')) {
         checkClearClass = 'js-embed-width';
      } else {
         checkClearClass = 'js-embed-type';
      }

      $$('.js-button-checkable.' + checkClearClass).removeClass('button-checked');
      $this.addClass('button-checked');

      let width = $$('.js-embed-width.button-checked')[0].get('data-embedWidth'),
         type = $$('.js-embed-type.button-checked')[0].get('data-embedType'),
         embedHtml = getEmbedHtml(width, type),
         textarea = $$('.js-embed-text')[0];

      $$('.js-embed-single').hide();
      $$('.js-embed-full').show();

      $('embedBoxIframe').set('html', embedHtml);

      textarea.set('value', embedHtml);
      textarea.select();
   }

   // Handle hash fragments that refer to hidden comments
   onHashFragmentElement(function (comment) {
      // Comments within steps are hidden by default
      let stepContainer = comment.getParent('.js-step');

      if (stepContainer) {
         toggleStepComments(stepContainer, 0, true);

         // Wait until the page loads so that the scroll position can be
         // calculated.
         onLoad(function () {
            comment.jumpTo({
               offset: 0,
               duration: 0,
            });
         });
      }
   });

   $$('.js-step').each(function (step) {
      let mainImg = step.getElement('.js-step-main-media');
      step.getElements('.js-step-thumbnail-container').each(function (thumb) {
         let swapImageWrap = swapImage.pass([thumb, mainImg]);
         thumb.addEvents({
            mouseenter: swapImageWrap,
            click: swapImageWrap,
         });
      });
   });

   // For guide images
   let showLargeImage = function (imageDiv, ev) {
      // Don't do anything if the target of the click wasn't the image.
      if ($(ev.target).get('tag') != 'img') {
         return;
      }
      // Don't try to show a large image for the "no image" image.
      if (imageDiv.hasClass('noImage') || $(ev.target).hasClass('js-no-link')) {
         return;
      }

      // Find the largest size available.
      let img = imageDiv.getElement('img.visible'),
         largest = img && img.get('data-biggest');

      if (largest) {
         window.open(largest, '_blank');
      }
   };

   $$('.js-step .stepMedia.stepImage').each(function (mediaDiv) {
      mediaDiv.addEvent('click', showLargeImage.bind(null, mediaDiv));
   });

   $$('.js-step .js-comment-container').each(function (el) {
      // Attach a CommentManager to every open comments section. Closed
      // comment sections will get a CommentManager when they are opened.
      if (el.getElement('.comments-container:not(.closed)')) {
         el.store('commentManager', new CommentManager(el));
      }
   });

   ContentDropdownGroup($$('.js-guide-details-container'));

   $$('.item-list li a').each(function (li) {
      new ItemHover(li);
   });

   $$('.publish-guide-banner').each(function (banner) {
      let guideid = banner.getProperty('data-guideid');
      let revisionid = banner.getProperty('data-guide-api-revisionid');
      let status = new StatusNotice(banner.getElement('.status'));

      banner.getElements('.button').addEvent('click', function () {
         let url = 'guides/' + guideid + '/public?revisionid=' + revisionid;
         new Request.API_2_0(url, {
            method: 'put',
            onFailure: function (xhr) {
               let response = JSON.parse(xhr.response);
               let msg = _js('Unable to publish guide.');
               if (response && response.message) {
                  msg = response.message;
               }
               status.error(msg);
               status.addCloseButton();
            },
            onSuccess: function (response) {
               status.good(_js('Successfully published.'));
               status.addCloseButton();
               window.location.reload();
            },
         }).send();
      });
   });

   window.addEvents({
      'click:relay(.js-screen-size-item)': function (ev, el) {
         let size = el.get('data-screen-size');
         $$('.js-video-container').set('data-video-size', size);
         $$('.js-screen-size-item').removeClass('active');
         el.addClass('active');
      },
      'click:relay(.js-show-comments)': function (ev, el) {
         let stepContainer = el.getParent('.js-step');
         toggleStepComments(stepContainer, undefined, undefined, el);
      },
      'click:relay(.js-expand-comments)': function (ev, el) {
         let stepContainers = $$('.js-step').filter(function (item) {
            return item.getElement('button.js-comment-count').get('data-numcomments') > 0;
         });
         let expand = !el.getElement('.expand').hasClass('hidden');

         SettingsCookie.set('expand-comments', expand.toString());

         stepContainers.each(function (stepContainer) {
            toggleStepComments(stepContainer, 0, expand);
         });

         el.getElements('.expand, .collapse').toggleClass('hidden');
      },
      'click:relay(.js-embed-type.js-button-checkable)': updateEmbedHtml,
      'click:relay(.js-embed-width.js-button-checkable)': updateEmbedHtml,
      'click:relay(.js-show-embed)': function (ev, el) {
         let embedBox = $('embedBox');
         if (embedBox && !embedAdded) {
            embedAdded = true;
            embedBox.appendHTML(
               '<div id="embedBoxIframe">' + getEmbedHtml(800, 'single') + '</div>'
            );
         }

         Modal.open({
            type: 'element',
            element: embedBox,
            onLoad: function (content) {
               let textarea = content.getElement('.js-embed-text');
               if (textarea) {
                  textarea.select();
               }
            },
         });
      },
      'click:relay(.js-show-shareable-link)': function (ev, el) {
         let modalEl = new Element('div.shareable-link-container').grab(
            new Element('h3', {
               text: _js('Shareable Link'),
            })
         );

         let modalInput = new Element('input', {
            type: 'text',
            value: el.get('data-shareable-link'),
         }).inject(modalEl);

         Modal.open({
            type: 'element',
            element: modalEl,
         });

         modalInput.select();
      },
      'click:relay(.js-subscribe-edit)': function () {
         Auth.required({
            from: $$('.js-subscribe-edit'),
            message: _js('Log in to subscribe.'),
            onAuthorize: LoadingIndicator.withEventHandler(
               function (ev, el) {
                  let guideid = App.guideid;
                  let langid = App.viewLangid;
                  let url =
                     'subscribe/' +
                     window.guide_constants.DocumentWatch('DOCTYPE_GUIDE') +
                     '/' +
                     guideid +
                     '/' +
                     langid +
                     '/' +
                     window.guide_constants.DocumentWatch('LEVEL_EDIT');
                  if ($$('.js-subscribe-edit')[0].hasClass('unsubscribed')) {
                     return new Request.API_2_0(url, {
                        method: 'put',
                        onSuccess: function (response) {
                           $$('.js-subscribe-edit')
                              .removeClass('unsubscribed')
                              .addClass('subscribed');
                        },
                     }).send();
                  } else {
                     return new Request.API_2_0(url, {
                        method: 'delete',
                        onSuccess: function (response) {
                           $$('.js-subscribe-edit')
                              .removeClass('subscribed')
                              .addClass('unsubscribed');
                        },
                     }).send();
                  }
               },
               {
                  pendingMessage: _js('Updating Subscription'),
                  successMessage: _js('Success'),
                  failureMessage: _js('Failure'),
               }
            ),
         });
      },
   });

   // eslint-disable-next-line no-var
   var upDownNav = new UpDownNavigation({
      items: $$('.js-step'),
      onBeforejump: function () {
         let context = 8;
         let notifications;
         if ((notifications = $('notifications'))) {
            context += notifications.getSize().y;
         }
         upDownNav.options.scrollContext = context;
      },
   });

   when($$('.time-travel .dropdown-wrapper'), function (dropdown) {
      dropdown.addEvent('valuechanged', function () {
         let targetRevision = this.getValue();
         let url = new URI(window.location.href);
         url.setData({ revisionid: targetRevision }, /* merge */ true).go();
      });
   });
});
