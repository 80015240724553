import { Auth } from 'Shared/auth';
import { Modal } from 'Shared/modal';

onDomReady(function () {
   // Success button (NOT DATA ENTRY VERSION)
   let successButton = $('successButton');
   let doneButton = $('doneButton');
   let cancelCompleted = $('cancelComplete');

   let toggleSuccessButton = function (success) {
      if (!success) {
         doneButton.addClass('hidden');
         successButton.removeClass('hidden');
         cancelCompleted.addClass('hidden');
      } else {
         successButton.addClass('hidden');
         doneButton.removeClass('hidden');
         cancelCompleted.removeClass('hidden');
      }
   };

   when(cancelCompleted, function () {
      cancelCompleted.addEvent('click', function (ev) {
         let text = doneButton.get('text');
         doneButton.set('text', _js('Processing...'));
         ev.stop();

         new Request.API_2_0('guides/' + App.guideid + '/completed', {
            method: 'DELETE',
            onSuccess: function (response) {
               toggleSuccessButton(false);
               doneButton.set('text', text);
            },
            onError: function (response) {
               Modal.alert(response.fail);
            },
         }).send();
      });
   });

   when(successButton, function () {
      successButton.addEvent('click', function (ev) {
         ev.stop();
         successButton.disabled = true;
         const enableButton = () => {
            successButton.disabled = false;
         };

         Auth.required({
            from: successButton,
            message: _js('Log in to record that you completed a repair.'),
            onAuthorize: function () {
               let text = successButton.get('text');
               successButton.set('text', _js('Processing...'));
               new Request.API_2_0('guides/' + App.guideid + '/completed', {
                  method: 'PUT',
                  onSuccess: function (response) {
                     enableButton();
                     toggleSuccessButton(true);
                     successButton.set('text', text);
                  },
                  onError: function (response) {
                     enableButton();
                     Modal.alert(response.fail);
                  },
                  onFailure: function (response, message) {
                     enableButton();
                     Modal.alert(message);
                  },
               }).send();
            },
            onCancel: enableButton,
         });
      });
   });

   when($('halfwayButton'), function (halfwayButton) {
      halfwayButton.addEvent('click', function (ev) {
         let divider = new Element('div', { class: 'hrContentMinor' });
         let container = new Element('div', { id: 'guideSteps' });

         $$('.step')
            .reverse()
            .each(function (step) {
               container.adopt(step, divider.clone());
            });

         container.replaces($('guideSteps'));

         // Scroll to the last step (which is now at the top of the page)
         $$('.step')[0].jumpTo({ offset: 5, duration: 0.75 });

         // Update conclusion banner
         $('halfwayConclusion').addClass('hidden');
         $('finishedConclusion').removeClass('hidden');

         // Update conclusion button
         this.addClass('hidden');
         $('successButton').removeClass('hidden');
      });
   });
});
